import React, { useEffect } from 'react';
import * as Sentry from "@sentry/nextjs";
import SignInFormContainer from '../containers/users/SignInFormContainer';
import Layout from '../components/Layout';
import Link from 'next/link';
import UrlQueryAlert from '../components/UrlQueryAlert';
import { Container, Row, Col } from 'reactstrap';
import { getSession } from 'next-auth/react';
import Router from 'next/router';
import { USER_HOME_ROUTE } from '../modules/users';
import Head from 'next/head';
import Image from 'next/image';

const LogIn = () => {

  useEffect(() => {
    const checkSessionAndSetUser = async () => {
      const session = await getSession();
      if (session && session.user) {
        // User is logged in, redirect to home route
        Router.replace(USER_HOME_ROUTE);
        Sentry.setUser({
          id: session.user.id,
          email: session.user.email,
        });
        Sentry.setTag("user.status", "returning user");
      } else {
        // Fallback strategy if no user data is available
        Sentry.setUser({ id: "unidentified-user" });
        Sentry.setTag("user.status", "unidentified");
        Sentry.captureMessage("User data not available at login", "info");
      }
    };

    checkSessionAndSetUser();
  }, []);

  return (
    <Layout hideHeader>
      <Head>
        <title>Login | Steady Install</title>
      </Head>
      <Container fluid className="form-container ">
        <Row className="justify-content-center">
          <Col xs="12" className="text-center">
            <Link href="/" className="d-block mb-4 mx-auto logo">
              <Image
                alt="Steady Install Logo"
                src="/static/images/logo-login.svg"
                width={200}
                height={100}
                className="relative-position"
              />
            </Link>
            <h1 className="mb-4 text-center">Login</h1>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col xs="12">
            <UrlQueryAlert className="text-center" />
            <SignInFormContainer />
            <div className="my-3 text-center">
              <Link href="forgot-password" className="uaTrack-forgot-password d-block">
                Forgot password?
              </Link>
              <Link href="register" className="uaTrack-link-register text-body">
                Don&apos;t have an account? Sign up free!
              </Link>
            </div>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

export default LogIn;
