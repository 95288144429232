import React from 'react'
import { useForm } from 'react-hook-form';
import { signIn as signInNext } from 'next-auth/react'
import { connect } from 'react-redux'
import { signIn } from '../../modules/users'
import SignInForm from '../../components/users/SignInForm'
import { withRouter } from 'next/router'
import { useRouter } from "next/navigation";
const mapDispatchToProps = { signIn }

const SignInFormContainer = () => {
  const { formState: { isSubmitting, isSubmitSuccessful } } = useForm();
  const router = useRouter();
  const onSubmit = async ({ email, password }) => {
    const res = await signInNext('credentials', { redirect: false, username: email, password })
    if (res?.error) {
      return 'Login failed' //update error
    }
    if (res && res.ok) {
      router.push('/dashboard')
    }
  }
    return (
      <SignInForm
        onSubmit={onSubmit}
        isSubmitting={isSubmitting}
        isSubmitSuccessful={isSubmitSuccessful}
      />
    )
}

const ConnectedSignInFormContainer = connect(null, mapDispatchToProps)(withRouter(SignInFormContainer))

export default ConnectedSignInFormContainer
