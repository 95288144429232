import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'next/router'
import CustomAlert from './CustomAlert/CustomAlert'


const propTypes = {
  className: PropTypes.string,
  router   : PropTypes.shape( {
    query: PropTypes.shape( {
      alertContext: PropTypes.oneOf( [
        'primary',
        'secondary',
        'success',
        'danger',
        'warning',
        'info',
        'light',
        'lighter',
        'dark',
      ] ),
      alertMsg    : PropTypes.string,
    } ),
  } ),
}

const UrlQueryAlert = ( { className, router } ) => {

  const { alertContext, alertMsg } = router.query

  return ( alertMsg && alertContext ) ?
    <CustomAlert 
      className={ className } 
      color={ alertContext }
      message={alertMsg} /> : null
}

UrlQueryAlert.propTypes = propTypes

export default withRouter( UrlQueryAlert )