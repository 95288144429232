import React, { useState } from 'react'
import { Alert } from 'reactstrap'

const CustomAlert = ( { className, color, message } ) => {
  const [showAlert, setAlert] = useState(true);

  return (
    <Alert 
      className={ className } 
      color={ color }
      isOpen={ showAlert }
      fade={false}
      toggle={
        () => { 
          let box = document.querySelector(".mt-n4.mb-3.text-center.text-md-left.row");
          if (!!box)
            box.style.setProperty('margin-top', '-2.25rem', 'important');
          
          setAlert(false);
        }
      }
    >
      {message}
    </Alert>
  )
}

export default CustomAlert;